<template>
  <vxe-modal :position="position" :lockView="false" :mask="false" v-model="visible" :showHeader="false" width="900" height="36">
    <template #default>
      <el-menu mode="horizontal" @select="selectHandle">
        <el-submenu index="测量工具" popper-class="my-dts-bg">
          <template slot="title">
            <span slot="title" style="color: white"><i class="el-icon-setting" style="color: white"></i> 测量工具</span>
          </template>
          <el-menu-item index="直线测量"><i class="el-icon-sort-up" style="color: white"></i> 直线测量</el-menu-item>
          <el-menu-item index="水平测量"><i class="el-icon-minus" style="color: white"></i> 水平测量</el-menu-item>
          <el-menu-item index="垂直测量"><i class="el-icon-top" style="color: white"></i> 垂直测量</el-menu-item>
          <el-menu-item index="投影面积"><i class="el-icon-crop" style="color: white"></i> 投影面积</el-menu-item>
          <el-menu-item index="地表面积"><i class="el-icon-crop" style="color: white"></i> 地表面积</el-menu-item>
        </el-submenu>
        <el-menu-item index="面剖切"><i class="el-icon-box" style="color: white"></i> 模型剖切</el-menu-item>
        <el-menu-item index="地形开关"><i class="el-icon-open" style="color: white"></i> {{ dixing.content }}</el-menu-item>
        <el-menu-item index="地形透明度"><i class="el-icon-sunset" style="color: white"></i> 地形透明度</el-menu-item>
        <el-menu-item index="倾斜摄影开关"><i class="el-icon-open" style="color: white"></i> {{ qingxiesheying.content }}</el-menu-item>
        <!--<el-menu-item index="高程开关"><i class="el-icon-open" style="color: white"></i> 高程开关</el-menu-item>-->
        <!-- <el-menu-item index="日照分析"><i class="el-icon-sunny" style="color: white"></i> 日照分析</el-menu-item> -->
        <!-- <el-menu-item index="挖填方分析"><i class="el-icon-crop" style="color: white"></i> 挖填方分析</el-menu-item> -->
        <!-- <el-menu-item index="可视域分析"><i class="el-icon-view" style="color: white"></i> 可视域分析</el-menu-item> -->
        <el-menu-item index="天气特效"><i class="el-icon-light-rain" style="color: white"></i> 天气特效</el-menu-item>
        <el-menu-item index="开启分屏"><i class="el-icon-reading" style="color: white"></i> {{ fenping.content }}</el-menu-item>
      </el-menu>
    </template>
  </vxe-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      position: {
        top: `2px`,
        left: `calc(50% - 410px)`
      },
      dixing: {
        content: '关闭地形',
        f: true
      },
      qingxiesheying: {
        content: '关闭倾斜摄影',
        f: true
      },
      fenping: {
        content: '开启分屏',
        f: false
      }
    }
  },
  mounted(){
    
  },
  methods: {
    init () {
      this.visible = true
    },
    selectHandle (index, indexPath) {
      if (index == '地形开关') {
        if (this.dixing.f) {
          this.dixing.f = false
          this.dixing.content = '显示地形'
        } else {
          this.dixing.f = true
          this.dixing.content = '关闭地形'
        }
      }
      else if (index == '倾斜摄影开关') {
        if (this.qingxiesheying.f) {
          this.qingxiesheying.f = false
          this.qingxiesheying.content = '显示倾斜摄影'
        } else {
          this.qingxiesheying.f = true
          this.qingxiesheying.content = '关闭倾斜摄影'
        }
      }
      else if (index == '开启分屏') {
        if (this.fenping.f) {
          this.fenping.f = false
          this.fenping.content = '开启分屏'
        } else {
          this.fenping.f = true
          this.fenping.content = '关闭分屏'
        }
      }
      this.$emit('select', index)
    }
  }
}
</script>
<style lang="less" scoped>
 ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .8) !important;
    overflow: hidden !important;
    
    border: rgb(255, 255, 255) 1px solid;
    border-radius: 40px;

    border-top-left-radius: 6px 4px;
    border-top-right-radius: 6px 4px;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    overflow: hidden !important;
    padding: 2px
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  ::v-deep .el-menu.el-menu--horizontal {
    padding-left: 10px;
    border-bottom: 0;
  }
  
  ::v-deep .el-menu--horizontal>.el-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    color: white !important;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 30px !important;
    line-height: 30px !important;
    color: white !important;
  }

  ::v-deep .el-menu--horizontal>.el-menu-item:hover {
    background-color: #003333!important;
    color: white !important;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: #003333!important;
    color: white !important;
  }

  ::v-deep .el-menu {
    color: white !important;
    background-color: transparent;
  }

  ::v-deep .el-menu--popup {
    color: white !important;
    background-color: transparent;
  }

  ::v-deep .el-menu-item {
    background-color: transparent !important;
    margin-left: -14px !important;
    color: white !important;
  }
  ::v-deep .el-submenu {
    background-color: transparent !important;
    margin-left: -14px !important;
    color: white !important;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
    color: white !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item {
    background-color: transparent !important;
    color: white !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item:hover {
    background-color: #003333!important;
  }
</style>