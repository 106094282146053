<template>
  <vxe-modal width="360" :position="{top: 300, left: 20}" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>分部分项工程</div>
    </span>
    <div class="dts-dialog-body">
      <vxe-table
          height="340"
          show-overflow
          :show-header="false"
          :row-config="{isHover: true, isCurrent: true, useKey: true}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :data="tableData"
          size="mini"
          @cell-click="clickHandle"
          @cell-dblclick="dblclickHandle"
          :scroll-y="{enabled: true}">
        <vxe-column field="name" title="名称" tree-node></vxe-column>
      </vxe-table>
    </div>
  </vxe-modal>
</template>

<script>
// 分部分项工程
export default {
  data () {
    return {
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        line: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadChildren,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      tableData: [],
      visible: false,
      prjId: ''
    }
  },
  mounted(){

  },
  methods: {
    init (prjId) {
      this.prjId = prjId
      this.visible = true
      this.tableData = []
      this.$nextTick(() => {
        this.$http.get('/mps/wbs/children',
          {
            params: {
              pid: 0,
              // contractId: '1593518794094878721' // TODO 暂时写死了方便测试，以后要将后台接口改造为支持按项目维度查询
              prjId: this.prjId
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
        }).catch(() => {})
      })
    },
    loadChildren({ row }) {
      // 异步加载子节点
      return new Promise(resolve => {
        this.$http.get('/mps/wbs/children',
          {
            params: {
              pid: row.id,
              prjId: this.prjId,
              // contractId: '1593518794094878721' // TODO 暂时写死了方便测试，以后要将后台接口改造为支持按项目维度查询
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {})
      })
    },
    // 单击wbs
    clickHandle({ row, triggerTreeNode }) {
      if (!triggerTreeNode) {
        this.$emit('click', row)
      }
    },
    // 双击wbs
    dblclickHandle({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
      this.$emit('dblclick', row)
    },
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
.dts-dialog {
  
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {

    ::v-deep .vxe-table {
      background: transparent !important;  //主体框透明透明
      //  border: rgb(40, 118, 193) 1px solid;
      border-bottom: none;
    }

    ::v-deep .vxe-table--body {
      background: transparent !important;
      border-bottom: none;
    }

    ::v-deep .vxe-table--body-wrapper {
      background: transparent !important;
    }

    ::v-deep .vxe-cell--label {
      font-size: 12px;
      color: #fff;
      text-align: center;
      background: transparent;
      //  border: rgb(40, 118, 193) 1px solid;
    }

    ::v-deep .vxe-body--row:hover {
      background-color: #006699 !important;
    }

    ::v-deep .row--current {
      background-color: #0066CC!important;
    }

    ::v-deep .vxe-tree--node-btn {
        color: #fff;
        background: transparent;
    }
  }
}
</style>