<template>
  <div class="container">
    <div class="player" id="dts_player"></div>
    <!-- 头部的标题组件 -->
    <dts-draw-vehicle-waypoint-header v-if="dtsDrawVehicleWaypointHeaderVisible" ref="dtsDrawVehicleWaypointHeader"></dts-draw-vehicle-waypoint-header>
    <!-- 项目选择（切换）组件 -->
    <dts-project-select v-if="dtsProjectSelectVisible" ref="dtsProjectSelect" @click="clickProjectHandle" :defaultPrjId="currentPrjId"></dts-project-select>
    <!-- 工具面板 -->
    <dts-draw-vehicle-waypoint-toolbar v-if="dtsDrawVehicleWaypointToolbarVisible" ref="dtsDrawVehicleWaypointToolbar" 
        @start="startHandle" 
        @stop="stopHandle"
        @save="saveHandle"
        @play="playHandle"
        @clear="clearHandle">
    </dts-draw-vehicle-waypoint-toolbar>
    <!-- 提交数据时，填写车载路线的名称 -->
    <el-dialog :visible.sync="inputTitleVisible" title="请填写车载路线的名称" :modal="false" width="500" :append-to-body="true">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
        <el-row>
          <el-col :span="24">
            <el-form-item label="车载路线名称" prop="title">
              <el-input v-model="dataForm.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="inputTitleVisible = false">关闭</el-button>
        <el-button @click="submitData" type="primary">提交</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// 车载漫游路线绘制组件
import debounce from 'lodash/debounce'
import { HashMap } from '@/utils'
import DtsProjectSelect from './dts/dts-project-select'
import DtsDrawVehicleWaypointToolbar from './dts/dts-draw-vehicle-waypoint-toolbar'
import DtsDrawVehicleWaypointHeader from './dts/dts-draw-vehicle-waypoint-header'

export default {
  data () {
    return {
      dtsProjectSelectVisible: false, // 项目选择弹窗
      dtsDrawVehicleWaypointToolbarVisible: false,
      dtsDrawVehicleWaypointHeaderVisible: false,
      currentPrjId: '', // 当前选中的项目ID
      options: {
        domId: 'dts_player',
        pid: '1',
        reset: true,
        apiOptions: {
          // 注意：只有在onReady之后才可以调用AirCityAPI接口
          onReady: this.onReady,
          onLog: this.onLog,      // 日志输出回调函数
          onEvent: this.onEvent,   // 三维场景交互事件回调函数
          useColorLog: true, // 可选参数，日志颜色，默认关闭
        },
        ui: {
          startupInfo: true,          //默认true，初始化过程中是否显示详细信息
          statusIndicator: true,      //默认true，左上角闪烁的状态指示灯，可以从不同的颜色看出当前的状态
          statusButton: true,         //默认false，是否在左下角显示信息按钮
          fullscreenButton: true,     //默认false，是否在右下角显示全屏按钮
          homeButton: true,           //默认false，是否在左下角显示“回到初始位置”按钮
          taskListBar: 1,             //默认1，是否在下方显示“任务队列（API调用队列）”信息（0: 永不显示；1: 执行比较耗时的操作时显示；2: 一直显示）
          mainUI: false,
        },
        events: {
          onVideoLoaded: this.onLoaded,   // 视频流加载成功回调函数
          onConnClose: this.onClose,      // 连接断开回调函数
          mouseKeyListener: {
            onMouseDown: this.onMouseDown,
            onMouseUp: this.onMouseUp,
            onKeyDown: this.onKeyDown
          }
        },
        
        showStatus: true,
        // 可选参数，是否显示页面加载详细信息，默认值为true
        showStartupInfo: false,
        
        // 可选参数，视频流加载成功回调函数
        // onloaded: onLoaded,
        
        // 可选参数，连接断开回调函数
        // onclose: onClose,

        // 可选参数，设置三维交互的键盘事件接收者
        // 注意：接收类型有视频标签(video)，网页文档(document)，空(none)
        keyEventTarget: 'video',
      },
      api: null,
      player: null,
      tileLayerSelCurr: '',// 当前最后一个选中的模型（用于右键菜单-隐藏模型）
      tileLayerSelMap: new HashMap(), // 点选的多个模型（比如：在模型与wbs关联挂接时，多次点选模型）
      wbsDblclickSelTileLayerMap: new HashMap(), // 双击wbs时后台查询到的关联的所有模型集合
      flagMap: new HashMap(), // 记录各种切换状态
      pos: {
        x: 0,
        y: 0
      },
      isMouseMoved: false, // 是否使用鼠标左键拖动了
      // 上次鼠标左键按下的时间（毫秒数）
      lastLeftMouseButtonDownTime: 0,
      // 绘制的路线点
      coordinates: [],
      inputTitleVisible: false,
      dataForm: {
        title: ''
      },
      // 漫游定时器
      vehicleRoamingTimer: null,
    }
  },
  computed: {
    dataRule () {
      return {
        title: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  components: {
    DtsProjectSelect,
    DtsDrawVehicleWaypointToolbar,
    DtsDrawVehicleWaypointHeader,
  },
  created () {
    this.loadDefaultProjectAndMakeAirCityPlayer()// 加载并选择默认项目，同时创建AirCityPlayer对象
  },
  mounted(){
    document.title = "车载漫游路线绘制"
  },
  destroyed(){
    
  },
  methods: {
    onReady (){
      // 开启事件监听
      this.api.settings.setEnableCameraMovingEvent(true)
      // MousePickMask.MouseClick | MousePickMask.MouseMove | MousePickMask.MouseHover
      // 7: click, move, hover: 全开
      // 0: click, move, hover: 全关
      this.api.settings.setMousePickMask(1)

      // 直接显示标题组件盖住logo
      this.dtsDrawVehicleWaypointHeaderVisible = true
      this.$nextTick(() => {
        this.$refs.dtsDrawVehicleWaypointHeader.init()
      })
      
      this.openProjectChangeDialog()
      this.openToolbarDialog()
    },
    onEvent(event) {
      //事件类型 参考交互事件类型枚举对象
      var eventType = event.eventtype
      //图层类型
      var layerType = event.Type
      //图层Id
      var layerId = event.Id || event.ID
      //点击ActorId
      var objectId = event.ObjectID
      //当前点击位置
      var objectLocation = event.MouseClickPoint
      switch (eventType) {
        //鼠标移动时触发此事件
        case "MouseMoved":
          // this.printLog('blue','鼠标移动')
          // this.mouseMoving = true
          break
          
        //鼠标左键点击时触发此事件
        case "LeftMouseButtonClick":
          // 关闭右键菜单
          // this.dtsContextmenuVisible = false

          if (layerType == 'TileLayer' && !this.isMouseMoved) {
            // 保存路线点
            // this.coordinates.push(objectLocation)

            // let mapKey = layerId + "_" + objectId
            // if (this.flagMap.get(mapKey)) {
            //   this.flagMap.put(mapKey, false)
            //   this.api.tileLayer.stopHighlightActor(layerId, objectId)
            //   this.tileLayerSelMap.remove(mapKey)
            // } else {
            //   this.flagMap.put(mapKey, true)
            //   this.api.tileLayer.highlightActor(layerId, objectId)
            //   // 记录选中的模型
            //   this.tileLayerSelMap.put(mapKey, {
            //     'layerId': layerId,
            //     'objectId': objectId,
            //   })
            // }
            // this.isMouseMoved = false
            // this.resetPos()
          }
          break
        default:
          ""
      }
    },
    onLog (s, nnl) {
      // this.printLog('blue', `s：${s}`)
      // this.printLog('blue', `nnl：${nnl}`)
    },
    onLoaded () {
      // this.printLog('green', '视频流加载成功')
    },
    onClose () {
      // this.printLog('red', '连接断开')
    },
    onMouseDown (e) {
      this.pos.x = e.x
      this.pos.y = e.y
      // 1：左键，2：右键，4：滚轮
      if (e.buttons === 1) {
        this.lastLeftMouseButtonDownTime = new Date().getTime()
      } else if (e.buttons === 2) {
        
      }
    },
    onMouseUp (e) {
      let dx = this.pos.x - e.x
	    let dy = this.pos.y - e.y
	    if (Math.sqrt(dx*dx+dy*dy) > 1) {
        this.isMouseMoved = true
      } else {
        this.isMouseMoved = false
      }
    },
    onKeyDown (e) {
      if (e && e.keyCode) {
        if (e.keyCode == 27) { // Esc键
          
        }
      }
    },
    // 注意：所有日志输出均使用该方法
    printLog(color, text) {
      console.log(`%c${text}`, `color: ${color}`);
    },
    // 打开项目选择（切换）弹窗
    openProjectChangeDialog() {
      this.dtsProjectSelectVisible = true
      this.$nextTick(() => {
        this.$refs.dtsProjectSelect.init()
      })
    },
    // 单击项目
    clickProjectHandle(p) {
      // this.printLog('orange', `切换了项目，id：${p.id}，name：${p.name}，pid：${p.dtsPid}`)
      this.currentPrjId = p.id
      this.options.pid = p.dtsPid
      this.api.destroy()
      this.dtsProjectSelectVisible = false
      this.dtsDrawVehicleWaypointToolbarVisible = false
      this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`, this.options)
      this.api = this.player.getAPI()
      this.coordinates = []
    },
    // 打开工具栏弹窗
    openToolbarDialog() {
      this.dtsDrawVehicleWaypointToolbarVisible = true
      this.$nextTick(() => {
        this.$refs.dtsDrawVehicleWaypointToolbar.init()
      })
    },
    // 清空模型缓存、取消高亮
    async clearTileLayerSelMap() {
      if (this.tileLayerSelMap.size() > 0) {
        let arr = this.tileLayerSelMap.values()
        for (const o of arr) {
          // 取消模型高亮
          await this.api.tileLayer.stopHighlightActor(o.layerId, o.objectId)
        }
        this.tileLayerSelMap.clear()
      }
    },
    // 加载并选择默认项目，同时创建AirCityPlayer对象
    loadDefaultProjectAndMakeAirCityPlayer() {
      this.$http.get('/mps/project/openapi/briefsForMe').then(({ data: res }) => {
        if (res.code === 0) {
          let projects = res.data
          if (!projects || projects.length == 0) {
            return this.$message({
              message: '未查询到授权的项目！',
              type: 'error',
              duration: 30000
            })
          }
          projects.forEach((p) => {
            if (p.dtsDefault == 1) {
              this.currentPrjId = p.id
              this.options.pid = p.dtsPid
            }
          })
          // 如果所有项目都没设置默认，则选中第一个
          if (!this.currentPrjId || this.currentPrjId.length == 0) {
            this.currentPrjId = projects[0].id
            this.options.pid = projects[0].dtsPid
          }
          // this.printLog('blue', `默认项目ID：${this.currentPrjId}，pid：${this.options.pid}`)

          this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`, this.options)
          this.api = this.player.getAPI()
          this.coordinates = []
        }
      }).catch(() => {
        this.$message({
          message: '查询项目信息时出现错误，请重新登录系统。',
          type: 'error',
          duration: 30000
        })
      })
    },
    resetPos() {
      this.pos = {
        x: 0,
        y: 0
      }
    },
    startHandle(){
      let lineType = 1 //0：直线，1：曲线
      let buildType = 0 //0：画多点线段， 1：画多边形
      let color = Color.Red //绘制颜色
      this.api.editHelper.setParam(lineType, buildType, color)
      this.api.editHelper.start()
    },
    async stopHandle() {
      let res = await this.api.editHelper.finish(true)
      this.coordinates = res.coordinates
      await this.api.polyline.add({
        id: Math.random(),
        coordinates: res.coordinates,
        color: Color.Red,
        style: 4,// 0：箭头样式1，1：箭头样式2，2：流动线，3：光流，4：	正常的固定线条，5：贴地模式，6：普通虚线，7：圆点虚线
        thickness: 1,// 线宽
        intensity: 1,// 亮度
        // flowRate: 0.5,
        depthTest: false
      })

      if (this.vehicleRoamingTimer) {
        clearInterval(this.vehicleRoamingTimer)
      }
      await this.api.vehicle.stop("vc1")
      await this.api.vehicle.clear()
    },
    async clearHandle(){
      this.api.polyline.clear()
      this.api.editHelper.cancel()
      this.coordinates = []

      if (this.vehicleRoamingTimer) {
        clearInterval(this.vehicleRoamingTimer)
      }
      await this.api.vehicle.stop("vc1")
      await this.api.vehicle.clear()
      await this.api.polyline.clear()
    },
    async playHandle() {
      if (this.vehicleRoamingTimer) {
        clearInterval(this.vehicleRoamingTimer)
      }
      await this.api.vehicle.stop("vc1")
      await this.api.vehicle.clear()
      await this.api.polyline.clear()
      
      if (!this.coordinates || this.coordinates.length == 0) {
        return this.$message({
          message: '您尚未绘制任何路线点',
          type: 'error',
          duration: 5000
        })
      }
      
      let pathArr = this.coordinates
      await this.api.polyline.add({
        id: 'polyline_' + new Date().getTime(),//折线唯一标识id
        coordinates: pathArr,//构成折线的坐标点数组
        coordinateType: 0,// 坐标系类型 0投影 1经纬度
        range: [1, 10000],//可视范围：[近裁距离, 远裁距离]，取值范围: [任意负值, 任意正值]
        color: Color.Green,//折线颜色
        thickness: 1,
        intensity: 0.6,//亮度
        flowRate: 0.5,//流速
        shape: 1,//类型 0：直线， 1：曲线
        depthTest: true,//是否做深度检测 开启后会被地形高度遮挡
        style: PolylineStyle.Arrow1,//折线样式 参考样式枚举：PolylineStyle
        tiling: 20 //材质贴图平铺比例
      })

      // 添加车辆模型
      await this.api.vehicle.add({
        "id": "vc1",
        "coordinateType": 0, // 0为投影坐标，1为WGS84类型，2为火星坐标系(GCJ02)，3为百度坐标系(BD09)
        "assetPath": "/JC_CustomAssets/VehicleLibrary/Exhibition/轿车_07",//资源库车辆路径
        "coordinate": pathArr[0], // 车辆的初始位置和方向数组
        "rotation": [0, 180, 0],
        "autoHeight": true
      })

      await this.api.vehicle.focus('vc1')

      // 运动轨迹点
      let wayPoints = []
      for (let i = 0; i < pathArr.length; i++) {
        wayPoints.push({ 
          "coordinate": pathArr[i],
          "gear": 3,
          "timeStamp": i
        })
      }

      // 镜头跟随
      await this.api.vehicle.focus('vc1', true, distance, 0, [2, 6, 0], [10, 0, 0])

      await this.api.vehicle.setWayPoint({
        "id": "vc1",
        "wayPoints": wayPoints
      })

      await this.api.vehicle.start([{
        id: 'vc1',
        timeStamp: 0,// 设置载具v1从wayPoints的第0秒开始运动
      }])
    },
    async saveHandle() {
      if (!this.coordinates || this.coordinates.length == 0) {
        return this.$message({
          message: '您尚未绘制任何路线点',
          type: 'error',
          duration: 5000
        })
      }
      // 打开窗口填写路线名称
      this.inputTitleVisible = true
    },
    // 提交数据
    submitData: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        if (!this.coordinates || this.coordinates.length == 0) {
          return this.$message({
            message: '未获取到车载路线点，请点击开始绘制。',
            type: 'error',
            duration: 5000
          })
        }
        const loading = this.$loading({
          lock: true,
          text: '正在保存...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let _coordinates = []
        for (const c of this.coordinates) {
          _coordinates.push({
            x: c[0],
            y: c[1],
            z: c[2],
          })
        }

        // 将车载路线名称和车载路线点数组一起提交
        this.$http.put('/opm/vehicleWaypoint/data',  {
          prjId: this.currentPrjId,
          title: this.dataForm.title,
          coordinates: _coordinates
        }).then(({ data: res }) => {
          loading.close()
          if (res.code !== 0) {
            return this.$message({
              message: res.msg,
              type: 'error',
              duration: 7000
            })
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 3000
          })
          this.inputTitleVisible = false
        }).catch((e) => {
          this.$message.error('服务器（OPM）出现异常：' + e)
        }).finally(() => {
          loading.close()
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),
  }
}
</script>
<style lang="scss" scoped>
  body {
    background-color: honeydew;
    font-family: Verdana;
    font-size: 14px;
    overflow: hidden;
  }
  .container {
    width: 100%;
    height: 100%;
  }
  .player {
    position: relative;
    background-color: black;
    // margin-top: 5px;
    width: 100%;
    height: calc(100vh - 0px);
  }
  ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  }
</style>