<template>
  <vxe-modal :zIndex="1500" width="300" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>进度模拟</div>
    </span>
    <div class="dts-dialog-body">
      <el-date-picker
        v-model="queryDaterange"
        type="daterange"
        value-format="yyyy-MM-dd"
        :range-separator="$t('datePicker.range')"
        :start-placeholder="$t('datePicker.start')"
        :end-placeholder="$t('datePicker.end')"
        :disabled="isStart" :clearable="false">
      </el-date-picker>
      <el-row :gutter="2" style="margin-top: 8px; text-align: center;">
        <el-col :span="12">
          <el-button size="mini" @click="startSimulation" :disabled="isStart">开始</el-button>
        </el-col>
        <el-col :span="12">
          <el-button size="mini" @click="stopSimulation">停止</el-button>
        </el-col>
      </el-row>
    </div>
  </vxe-modal>
</template>
<!--进度模拟-->
<script>
import debounce from 'lodash/debounce'
import {getUUID} from '@/utils'
export default {
  data () {
    return {
      visible: false,
      position: {
        top: 20,
        left: `calc(100% - 320px)`
      },
      queryDaterange: null,
      startDate: null,
      endDate: null,
      isStart: false,
      prjId: null
    }
  },
  watch: {
    queryDaterange (val) {
      if (val == null) {
        this.startDate = null
        this.endDate = null
      } else {
        this.startDate = val[0]
        this.endDate = val[1]
      }
    },
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        // 日期选择默认设置当前周
        let nowDate = new Date()

        this.setQueryDaterange()
      })
    },
    // 开始
    startSimulation: debounce(function () {
      if (this.checkDate()) {
        this.isStart = true
        this.$emit('startSimulation',{startDate: this.startDate,endDate: this.endDate})
      }
    },1000,{'leading': true,'trailing': false}),

    // 停止
    stopSimulation: debounce(function () {
      this.isStart = false
      this.$emit('stopSimulation')
    },1000,{'leading': true,'trailing': false}),

    checkDate () {
      if (!this.startDate) {
        this.$message.error('请先选择日期')
        return false
      }
      return true
    },

    // 设置日期范围 (取项目下周计划的最小开始日期与最大结束日期)
    setQueryDaterange () {
      if (this.prjId) {
        this.$http.get('/opm/weeklySchedule/minAndMaxDate/' + this.prjId).then(({data: res}) => {
          if (res.code !== 0) {
            // return this.$message.error(res.msg)
            // 日期选择默认设置当前月
            this.queryDaterange = [this.getMonthFrist(),this.getMonthLast()]
          }
          if (res.data && res.data.minStartDate && res.data.maxEndDate) {
            this.queryDaterange = [res.data.minStartDate,res.data.maxEndDate]
          } else {
            // 日期选择默认设置当前月
            this.queryDaterange = [this.getMonthFrist(),this.getMonthLast()]
          }
        }).catch(() => {
          // 日期选择默认设置当前月
          this.queryDaterange = [this.getMonthFrist(),this.getMonthLast()]
        })
      }
    },

    // 获取本周的第一天，返回格式: YYYY-mm-dd
    getCurrentWeekFirstDay (date) {
      var days = date.getDay();
      days = days == 0 ? 7 : days;

      let weekFirstDay = new Date(date - (days - 1) * 86400000);
      let firstMonth = Number(weekFirstDay.getMonth()) + 1;
      if (firstMonth < 10) {
        firstMonth = '0' + firstMonth;
      }
      let weekFirstDays = weekFirstDay.getDate();
      if (weekFirstDays < 10) {
        weekFirstDays = '0' + weekFirstDays;
      }
      return weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays;
    },

    // 获取本周的最后一天，返回格式: YYYY-mm-dd
    getCurrentWeekLastDay (date) {
      var days = date.getDay();
      days = days == 0 ? 7 : days;
      let weekFirstDay = new Date(date - (days - 1) * 86400000);
      let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000);
      let lastMonth = Number(weekLastDay.getMonth()) + 1;
      if (lastMonth < 10) {
        lastMonth = '0' + lastMonth;
      }
      let weekLastDays = weekLastDay.getDate();
      if (weekLastDays < 10) {
        weekLastDays = '0' + weekLastDays;
      }
      return weekLastDay.getFullYear() + '-' + lastMonth + '-' + weekLastDays;
    },
    getMonthFrist () {
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0

      return [y,m,d].join("-")
    },

    getMonthLast () {
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = new Date(y,m,0).getDate(); //获取当月最后一日
      m = m < 10 ? '0' + m : m; //月份补 0
      d = d < 10 ? '0' + d : d; //日数补 0

      return [y,m,d].join("-")
    }
  }
}
</script>
<style lang="less" scoped>
  ::v-deep .el-input__inner {
    width: 270px;
  }

  ::v-deep .el-range-separator {
    padding: 0 !important;
  }

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    /*backdrop-filter: blur(3 px);*/
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog {

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }

  }

  .dts-dialog-body {
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333 !important;
    }
  }
}
</style>