<template>
  <vxe-modal width="70" :position="position" :lockView="false" :mask="false" v-model="visible" :showHeader="false">
    <!-- <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>工具栏</div>
      </span>
    </template> -->
    <template #default>
      <el-menu :collapse="true" @select="selectHandle">
        <!-- <el-menu-item index="项目切换" popper-class="my-dts-bg">
          <i class="el-icon-s-fold" style="color: white"></i>
          <span slot="title">项目切换</span>
        </el-menu-item> -->
        <el-menu-item index="图层管理" popper-class="my-dts-bg">
          <i class="el-icon-office-building" style="color: white"></i>
          <span slot="title">图层管理</span>
        </el-menu-item>
        <!-- <el-menu-item index="形象进度" popper-class="my-dts-bg">
          <i class="el-icon-odometer" style="color: white"></i>
          <span slot="title">形象进度</span>
        </el-menu-item>
        <el-menu-item index="进度模拟" popper-class="my-dts-bg">
          <i class="el-icon-s-marketing" style="color: white"></i>
          <span slot="title">进度模拟</span>
        </el-menu-item>
        <el-menu-item index="质量巡检" popper-class="my-dts-bg">
          <i class="el-icon-add-location" style="color: white"></i>
          <span slot="title">质量巡检</span>
        </el-menu-item>
        <el-menu-item index="安全巡检" popper-class="my-dts-bg">
          <i class="el-icon-add-location" style="color: white"></i>
          <span slot="title">安全巡检</span>
        </el-menu-item> -->
        <el-menu-item index="查看属性" popper-class="my-dts-bg">
          <i class="el-icon-info" style="color: white"></i>
          <span slot="title">查看属性</span>
        </el-menu-item>
        <el-menu-item index="统计图表" popper-class="my-dts-bg">
          <i class="el-icon-picture" style="color: white"></i>
          <span slot="title">统计图表</span>
        </el-menu-item>
        <!-- <el-submenu index="三维漫游" popper-class="my-dts-bg">
          <template slot="title">
            <i class="el-icon-map-location" style="color: white"></i>
            <span slot="title">三维漫游</span>
          </template>
          <el-menu-item index="飞行漫游"><i class="el-icon-position" style="color: white"></i> 飞行漫游</el-menu-item>
          <el-menu-item index="车载漫游"><i class="el-icon-truck" style="color: white"></i> 车载漫游</el-menu-item>
        </el-submenu> -->
        <!-- <el-submenu index="常用工具" popper-class="my-dts-bg">
          <template slot="title">
            <i class="el-icon-files" style="color: white"></i>
            <span slot="title">常用工具</span>
          </template>
          <el-menu-item index="直线测量"><i class="el-icon-sort-up" style="color: white"></i> 直线测量</el-menu-item>
          <el-menu-item index="水平测量"><i class="el-icon-minus" style="color: white"></i> 水平测量</el-menu-item>
          <el-menu-item index="垂直测量"><i class="el-icon-top" style="color: white"></i> 垂直测量</el-menu-item>
          <el-menu-item index="投影面积"><i class="el-icon-crop" style="color: white"></i> 投影面积</el-menu-item>
          <el-menu-item index="地表面积"><i class="el-icon-crop" style="color: white"></i> 地表面积</el-menu-item>
          <el-menu-item index="面剖切"><i class="el-icon-box" style="color: white"></i> 模型剖切</el-menu-item>
          <el-menu-item index="地形开关"><i class="el-icon-open" style="color: white"></i> 地形开关</el-menu-item>
          <el-menu-item index="地形透明度"><i class="el-icon-sunset" style="color: white"></i> 地形透明度</el-menu-item>
          <el-menu-item index="倾斜摄影开关"><i class="el-icon-open" style="color: white"></i> 倾斜摄影开关</el-menu-item>
          <el-menu-item index="天气特效"><i class="el-icon-light-rain" style="color: white"></i> 天气特效</el-menu-item>
        </el-submenu> -->
      </el-menu>
    </template>
  </vxe-modal>
</template>

<script>
// TODO 该组件暂时是在右侧显示
export default {
  data () {
    return {
      visible: false,
      position: {
        top: 220,
        left: `calc(100% - 72px)`
      }
    }
  },
  mounted(){
    
  },
  methods: {
    init () {
      this.visible = true
    },
    selectHandle (index, indexPath) {
      this.$emit('select', index)
    }
  }
}
</script>
<style lang="less" scoped>
 ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    overflow: hidden !important;
    
    border: rgb(255, 255, 255) 1px solid;
    border-radius: 30px;
    
    // 左右结构的梯形
    border-top-right-radius: 6px 4px;
    border-bottom-right-radius: 6px 4px;
  }


  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    overflow: hidden !important;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  ::v-deep .el-menu {
    color: white;
    background-color: transparent;
  }

  ::v-deep .el-submenu .el-submenu__title:hover {
    background-color: #003333!important;
  }
  
  ::v-deep .el-menu--popup {
    color: white;
    background-color: transparent;
  }

  // ::v-deep .el-table tr:hover {
  //   cursor: pointer;
  //   background-color: #003333!important;
  // }

  ::v-deep .el-menu-item {
    background-color: transparent !important;
    margin-left: -14px !important;
  }
  ::v-deep .el-submenu {
    background-color: transparent !important;
    margin-left: -14px !important;
  }
</style>