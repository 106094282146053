<template>
  <vxe-modal width="360" :position="{top: 10, left: 10}" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>图层管理</div>
    </span>
    <div class="dts-dialog-body">
      <el-card style="height: 300px; overflow: auto;">
        <div slot="header" class="clearfix">
          <span>图层管理</span>
        </div>
        <el-tree
          :data="treeData"
          show-checkbox
          node-key="id"
          :default-checked-keys="checkedKeys"
          :props="defaultProps"
          @check="layerCheckHandle"
          :expand-on-click-node="false"
          @node-click="handleNodeClick">
        </el-tree>
      </el-card>
      <el-card>
        <div slot="header" class="clearfix">
          <span>分部分项工程</span>
        </div>
        <vxe-table
          height="340"
          show-overflow
          :show-header="false"
          :row-config="{isHover: true, isCurrent: true, useKey: true}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :data="tableData"
          size="mini"
          @cell-click="clickHandle"
          @cell-dblclick="dblclickHandle"
          :scroll-y="{enabled: true}">
          <vxe-column field="name" title="名称" tree-node></vxe-column>
        </vxe-table>
      </el-card>
    </div>
  </vxe-modal>
</template>

<script>
// 图层管理面板
export default {
  data () {
    return {
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      checkedKeys: [],
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        line: true,
        lazy: true,
        hasChild: 'hasChildren',
        loadMethod: this.loadChildren,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      tableData: [],
      visible: false,
      prjId: '',
      //定义点击次数,默认0次
      treeClickCount: 0,
    }
  },
  mounted(){

  },
  methods: {
    init (prjId, layerArr, checkedKeys) {
      this.treeData = layerArr
      this.checkedKeys = checkedKeys
      this.prjId = prjId
      this.visible = true
      this.tableData = []
      this.$nextTick(() => {
        this.$http.get('/mps/wbs/children',
          {
            params: {
              pid: 0,
              // contractId: '1593518794094878721' // TODO 暂时写死了方便测试，以后要将后台接口改造为支持按项目维度查询
              prjId: this.prjId
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
        }).catch(() => {})
      })
    },
    loadChildren({ row }) {
      // 异步加载子节点
      return new Promise(resolve => {
        this.$http.get('/mps/wbs/children',
          {
            params: {
              pid: row.id,
              prjId: this.prjId,
              // contractId: '1593518794094878721' // TODO 暂时写死了方便测试，以后要将后台接口改造为支持按项目维度查询
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {})
      })
    },
    // 单击wbs
    clickHandle({ row, triggerTreeNode }) {
      if (!triggerTreeNode) {
        this.$emit('click', row)
      }
    },
    // 双击wbs
    dblclickHandle({ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, $event }) {
      this.$emit('dblclick', row)
    },
    // 图层勾选/反选
    layerCheckHandle(data, checkedData) {
      let showIds = []
      let hideIds = []
      if (this.checkedKeys && this.checkedKeys.length > 0) {
        for (let i = 0; i < this.checkedKeys.length; i++) {
          let _id = this.checkedKeys[i]
          // 判断出当前未选中的节点
          if (!checkedData.checkedKeys.includes(_id) && !checkedData.halfCheckedKeys.includes(_id)) {
            hideIds.push(_id)
          } else {
            showIds.push(_id)
          }
        }
      }
      this.$emit('layerCheckChange', showIds, hideIds)
    },
    // 点击图层事件
    handleNodeClick(data, node) {
      // 记录点击次数
      this.treeClickCount++;
      // 单次点击次数超过2次不作处理,直接返回,也可以拓展成多击事件
      if (this.treeClickCount >= 2) {
        return;
      }
      // 计时器,计算300毫秒为单位
      this.timer = window.setTimeout(() => {
        if (this.treeClickCount == 1) { // 单击事件
          // 把次数归零
          this.treeClickCount = 0
          // 暂时单击无任何逻辑
          // this.console('单击事件')
        } else if (this.treeClickCount > 1) { // 双击事件
          // 把次数归零
          this.treeClickCount = 0
          // 定位图层
          if (node.isLeaf) {
            this.$emit('layerDblClick', data.id)
          }
        }
      }, 300)
    }
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
.dts-dialog {
  
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    ::v-deep .el-card {
      background: transparent;
      color: white;
    }

    ::v-deep .el-card__header {
      padding: 5px 5px;
    }

    ::v-deep .el-card__body {
      background: transparent;
      padding: 0px !important;
    }

    ::v-deep .el-tree {
      background: transparent;
      color: white;
    }

    ::v-deep .el-tree-node.is-focusable > .el-tree-node__content {
      background: transparent;
    }

    ::v-deep .el-tree-node.is-current > .el-tree-node__content {
      background-color: #006699 !important;
    }

    ::v-deep .el-tree-node > .el-tree-node__content:hover {
      background-color: #006699 !important;
    }

    ::v-deep .vxe-table {
      background: transparent;
      // border: rgb(40, 118, 193) 1px solid;
      border-bottom: none;
    }

    ::v-deep .vxe-table--body {
      background: transparent;
      border-bottom: none;
    }

    ::v-deep .vxe-table--body-wrapper {
      background: transparent;
    }

    ::v-deep .vxe-cell--label {
      font-size: 12px;
      color: #fff;
      text-align: center;
      background: transparent;
      //  border: rgb(40, 118, 193) 1px solid;
    }

    ::v-deep .vxe-body--row:hover {
      background-color: #006699 !important;
    }

    ::v-deep .row--current {
      background-color: #006699 !important;
    }

    ::v-deep .vxe-tree--node-btn {
      color: #fff;
      background: transparent;
    }
  }
}
</style>