<template>
  <vxe-modal width="420" height="480" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>构件属性</div>
      </span>
    </template>
    <template #default>
      <div class="dts-dialog-body">
        <el-card class="box-card">
          <!-- 去掉示标题更好看 -->
          <!-- <div slot="header" class="clearfix box-card-header">
            <span>基本信息</span>
          </div> -->
          <div class="wbs-info">编码：{{ wbs.code }}</div>
          <div class="wbs-info">名称：{{ wbs.name }}</div>
          <div class="wbs-info">桩号：{{ wbs.stakeNo }}</div>
          <div class="wbs-info">图号：{{ wbs.drawingNo }}</div>
          <div class="wbs-info">开工：{{ wbs.startDate }}</div>
          <div class="wbs-info">完工：{{ wbs.endDate }}</div>
        </el-card>
        <el-card class="box-card">
          <!-- 0#清单+累计计量 -->
          <el-table ref="subBoqTable" :data="subBoqTableData" style="width: 100%" v-loading="subBoqTableLoading"
            :header-cell-style="{background: 'rgba(40, 118, 193, .5)', color: 'white', textAlign: 'center'}">
            <el-table-column prop="boqCode" label="清单编码" width="75"></el-table-column>
            <el-table-column prop="boqName" label="清单名称"></el-table-column>
            <el-table-column prop="num" label="清单数量" align="right" width="75"></el-table-column>
            <el-table-column prop="measNm" label="累计计量" align="right" width="75"></el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card">
          <!-- 去掉示标题更好看 -->
          <!-- <div slot="header" class="clearfix box-card-header">
            <span>构件属性</span>
          </div> -->
          <el-table ref="propValTable" :data="propValTableData" style="width: 100%" v-loading="propValTableLoading"
            :header-cell-style="{background: 'rgba(40, 118, 193, .5)', color: 'white', textAlign: 'center'}">
            <el-table-column prop="propName" label="属性名"></el-table-column>
            <el-table-column prop="val" label="属性值" align="center"></el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card">
          <el-table ref="ossTable" :data="ossTableData" style="width: 100%" v-loading="ossTableLoading"
            :header-cell-style="{background: 'rgba(40, 118, 193, .5)', color: 'white', textAlign: 'center'}">
            <el-table-column prop="name" label="文件名称" header-align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDownload(scope.row.url,scope.row)">{{ scope.row.name }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
import Cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      prjId: '',
      wbs: {
        code: '',
        name: '',
        stakeNo: '',
        drawingNo: '',
      },
      // 0#清单
      subBoqTableData: [],
      subBoqTableLoading: false,

      // 自定义构件属性
      propValTableData: [],
      propValTableLoading: false,

      // 图纸等电子资料
      ossTableData: [],
      ossTableLoading: false,

      position: {
        top: 30,
        left: `calc(100% - 500px)`
      },
    }
  },
  methods: {
    init (prjId, tileLayerCurSel) {
      this.prjId = prjId
      this.visible = true
      this.wbs = {} // wbs基本信息
      this.propValTableData = [] // 构件属性
      this.$nextTick(() => {
        // 根据 layerId、objectId 查询对应的wbs
        this.$http.get('/opm/dtsWbs/wbs', {
          params: {
            prjId: prjId,
            layerId: tileLayerCurSel.layerId,
            objectId: tileLayerCurSel.objectId,
          }
        }).then(({ data: res }) => {
          if (res.code === 0) {
            if (res.data && res.data.id) {
              this.wbs = res.data
              // 查询0#清单及累计计量数量
              this.loadSubBoqTableData(this.wbs.id)
              // 查询自定义构件属性
              this.loadPropTableData(this.wbs.id)
              // 查询附件
              this.loadOssTableData(this.wbs.id)
            } else {
              this.wbs = {}
              this.$message({
                message: '未查询到相对应的分部分项工程。',
                type: 'error',
                duration: 3000
              })
            }
          } else {
            this.$message({
              message: '查询分部分项工程时出现错误，请稍后重试。',
              type: 'error',
              duration: 3000
            })
          }
        }).catch(() => {
          this.wbs = {}
        })
      })
    },
    // 查询0#清单（包括累计计量）
    loadSubBoqTableData(wbsId) {
      this.subBoqTableDataLoading = true
      this.$nextTick(() => {
        this.$http.get(`mps/subBoq/bimProps/${this.prjId}/${wbsId}`).then(({ data: res }) => {
          this.subBoqTableDataLoading = false
          if (res.code === 0) {
            this.subBoqTableData = res.data
          }
        }).catch(() => {
          this.subBoqTableDataLoading = false
        })
      })
    },
    // 查询自定义构件属性
    loadPropTableData(wbsId) {
      this.propValTableLoading = true
      this.$nextTick(() => {
        this.$http.get('mps/wbs/propVal', {
          params: {
            wbsId: wbsId,
          }
        }).then(({ data: res }) => {
          this.propValTableLoading = false
          if (res.code === 0) {
            this.propValTableData = res.data
          }
        }).catch(() => {
          this.propValTableLoading = false
        })
      })
    },
    // 查询该分部分项关联的附件
    loadOssTableData(wbsId) {
      this.ossTableLoading = true
      this.$nextTick(() => {
        this.$http.get(`oss/file/list/${wbsId}`).then(({ data: res }) => {
          this.ossTableLoading = false
          if (res.code === 0) {
            this.ossTableData = res.data
          }
        }).catch(() => {
          this.ossTableLoading = false
        })
      })
    },
    // 点击附件名称，下载附件
    handleDownload(url,row) {
      // 保存用户下载记录
      if (row) {
        this.$http['post']('/oss/download/', {
          realName: this.$store.state.user.realName,
          ossId: row.id
        }).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
        }).catch(() => {
          // do nothing
        })
      }
      window.open(url)
    },
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
 .dts-dialog {
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: white 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    .clearfix:before, .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both
    }
    .wbs-info {
      padding: 2px 6px;
    }

    .box-card {
      color: white;
      background-color: transparent;
    }

    ::v-deep .el-card__header {
      background: rgba(0, 0, 0, .5) !important;
    }

    ::v-deep .el-card__body {
      padding: 0px !important;
    }
    
    ::v-deep .el-table {
      color: white;
      background-color: transparent;
      // border: white 1px solid;
      border-bottom: none;
    }

    // ::v-deep .el-table tr:hover {
    //   cursor: pointer;
    //   background-color: #003333!important;
    // }

    ::v-deep .el-table tr {
      background-color: transparent !important;
    }
    ::v-deep .el-table__body tr:hover > td {
      cursor: pointer;
      background-color: #003333!important;
    }
  }
}
</style>