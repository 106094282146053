<template>
  <vxe-modal width="200" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>安全巡检</div>
    </span>
    <div class="dts-dialog-body">
      <el-row :gutter="2" style="margin-top: 1px">
        <el-col :span="24"><el-button @click="stop">关闭</el-button></el-col>
      </el-row>
    </div>
  </vxe-modal>
</template>
<script>
  import Cookies from 'js-cookie'
  import debounce from 'lodash/debounce'
  import { getUUID } from '@/utils'
  export default {
    data () {
      return {
        visible: false,
        position: {
          top: 140,
          left: `calc(100% - 300px)`
        }
      }
    },
    methods: {
      init () {
        this.visible = true
      },
      stop() {
        this.$emit('stop')
      },
    }
  }
</script>
<style lang="less" scoped>
  .dts-dialog {

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
    // backdrop-filter: blur(3px); //模糊
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    text-align: right;

    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }

    ::v-deep .el-col-4 {
      width: 40px !important;
    }
    ::v-deep .el-col-10 {
      width: 70px !important;
    }
  }
}
</style>