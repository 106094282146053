<template>
  <div class="container">
    <div class="player" id="dts_player"></div>
    <!-- 顶部标题组件 -->
    <dts-bind-header v-if="dtsBindHeaderVisible" ref="dtsBindHeader"></dts-bind-header>
    <!-- 项目选择（切换）组件 -->
    <dts-project-select v-if="dtsProjectSelectVisible" ref="dtsProjectSelect" @click="clickProjectHandle" :defaultPrjId="currentPrjId"></dts-project-select>
    <!-- wbs选择组件 -->
    <dts-wbs-select v-if="dtsWbsSelectVisible" ref="dtsWbsSelect" @click="wbsClickHandle" @dblclick="wbsDblclickHandle"></dts-wbs-select>
    <!-- 工具面板 -->
    <dts-wbs-bind-toolbar v-if="dtsWbsBindToolbarVisible" ref="dtsWbsBindToolbar" @click="wbsBindToolbarClickHandle" @focusActorChangeHandle="focusActorChangeHandle"></dts-wbs-bind-toolbar>
    <!-- 右键菜单 -->
    <!-- <dts-contextmenu v-if="dtsContextmenuVisible" ref="dtsContextmenu" @hideActor="hideActor"></dts-contextmenu> -->
    <!-- 隐藏或显示模型工具面板 -->
    <dts-actor-hide-show v-if="dtsActorHideShowVisible" ref="dtsActorHideShow" @hideActor="hideActor" @showActor="showActor"></dts-actor-hide-show>
  </div>
</template>
<script>
import { HashMap } from '@/utils'
import DtsProjectSelect from './dts/dts-project-select'
import DtsWbsSelect from './dts/dts-wbs-select'
import DtsWbsBindToolbar from './dts/dts-wbs-bind-toolbar'
// import DtsContextmenu from './dts/dts-contextmenu.vue'
import DtsActorHideShow from './dts/dts-actor-hide-show'
import DtsBindHeader from './dts/dts-bind-header'

export default {
  data () {
    return {
      dtsBindHeaderVisible: false,
      dtsProjectSelectVisible: false, // 项目选择弹窗
      dtsWbsSelectVisible: false,// wbs选择弹窗
      dtsWbsBindToolbarVisible: false,// wbs关联模型工具栏弹窗
      currentPrjId: '', // 当前选中的项目ID
      currentWbs: '',// 当前选中的wbs
      options: {
        domId: 'dts_player',
        // 工程id
        pid: '1',
        reset: true,
        apiOptions: {
          // 注意：只有在onReady之后才可以调用AirCityAPI接口
          onReady: this.onReady,
          onLog: this.onLog,      // 日志输出回调函数
          onEvent: this.onEvent,   // 三维场景交互事件回调函数
          useColorLog: true, // 可选参数，日志颜色，默认关闭
        },
        ui: {
          startupInfo: true,          //默认true，初始化过程中是否显示详细信息
          statusIndicator: true,      //默认true，左上角闪烁的状态指示灯，可以从不同的颜色看出当前的状态
          statusButton: true,         //默认false，是否在左下角显示信息按钮
          fullscreenButton: true,     //默认false，是否在右下角显示全屏按钮
          homeButton: true,           //默认false，是否在左下角显示“回到初始位置”按钮
          taskListBar: 1,             //默认1，是否在下方显示“任务队列（API调用队列）”信息（0: 永不显示；1: 执行比较耗时的操作时显示；2: 一直显示）
          mainUI: false,
        },
        events: {
          onVideoLoaded: this.onLoaded,   // 视频流加载成功回调函数
          onConnClose: this.onClose,      // 连接断开回调函数
          mouseKeyListener: {
            onMouseDown: this.onMouseDown,
            onMouseUp: this.onMouseUp,
            onKeyDown: this.onKeyDown
          }
        },
        
        showStatus: true,
        // 可选参数，是否显示页面加载详细信息，默认值为true
        showStartupInfo: false,
        
        // 可选参数，视频流加载成功回调函数
        // onloaded: onLoaded,
        
        // 可选参数，连接断开回调函数
        // onclose: onClose,

        // 可选参数，设置三维交互的键盘事件接收者
        // 注意：接收类型有视频标签(video)，网页文档(document)，空(none)
        keyEventTarget: 'video',
      },
      api: null,
      player: null,
      tileLayerSelCurr: '',// 当前最后一个选中的模型（用于右键菜单-隐藏模型）
      tileLayerSelMap: new HashMap(), // 点选的多个模型（比如：在模型与wbs关联挂接时，多次点选模型）
      wbsDblclickSelTileLayerMap: new HashMap(), // 双击wbs时后台查询到的关联的所有模型集合
      flagMap: new HashMap(), // 记录各种切换状态
      pos: {
        x: 0,
        y: 0
      },
      isMouseMoved: false, // 是否使用鼠标左键拖动了
      focusActor: true, // 是否在点击wbs时自动定位对应的模型
      // 上次鼠标左键按下的时间（毫秒数）
      lastLeftMouseButtonDownTime: 0,
      // dtsContextmenuVisible: false,
      dtsActorHideShowVisible: false,
      hideActors: new HashMap(), // 单独记录隐藏的模型（保存关联关系时，用户可自行决定是否包含隐藏的模型）
    }
  },
  components: {
    DtsProjectSelect,
    DtsWbsSelect,
    DtsWbsBindToolbar,
    // DtsContextmenu,
    DtsActorHideShow,
    DtsBindHeader,
  },
  created () {
    this.loadDefaultProjectAndMakeAirCityPlayer()// 加载并选择默认项目，同时创建AirCityPlayer对象
  },
  mounted(){
    document.title = "三维数字孪生平台-模型挂接"
  },
  destroyed(){
    
  },
  methods: {
    onReady (){
      // this.printLog('green', '三维场景渲染成功，请开始在_onReady回调函数内进行二次开发。')
      
      // 要动态根据项目信息设置相机位置
      // this.api.camera.set(492035.37, 2488806.75, 402.62, -15.0, -173.0, 0.2)
      // this.api.camera.get((r) => {
        // this.printLog('green', `相机位置: ${r.x}, ${r.y}, ${r.z}, ${r.pitch}, ${r.yaw}`)
      // })

      // 显示右侧导航栏
      // this.api.settings.setMainUIVisibility(true)

      // 开启事件监听
      this.api.settings.setEnableCameraMovingEvent(true)
      this.api.settings.setMousePickMask(7)

      // 创建各个标注（如：项目选择弹窗、分部分项工程弹窗、视频监控点、环境监测点、三维巡检点、人员定位点等的标注点）
      // this.addMarkers()

      // 直接显示标题组件（盖住logo）
      this.dtsBindHeaderVisible = true
      this.$nextTick(() => {
        this.$refs.dtsBindHeader.init(this.currentPrjId)
      })

      this.openProjectChangeDialog()
      this.openWbsSelectDialog()
      this.openWbsBindToolbarDialog()
      this.openActorHideShowVisible()
    },
    onEvent(event) {
      //事件类型 参考交互事件类型枚举对象
      var eventType = event.eventtype
      //图层类型
      var layerType = event.Type
      //图层Id
      var layerId = event.Id || event.ID
      //点击ActorId
      var objectId = event.ObjectID
      //当前点击位置
      var objectLocation = event.MouseClickPoint
      switch (eventType) {
        //鼠标移动时触发此事件
        case "MouseMoved":
          // this.printLog('blue','鼠标移动')
          // this.mouseMoving = true
          break
          
        //鼠标左键点击时触发此事件
        case "LeftMouseButtonClick":
          // 关闭右键菜单
          // this.dtsContextmenuVisible = false

          if (layerType == 'TileLayer' && !this.isMouseMoved) {
            // 反复点选同一个模型时，高亮和不高亮来回切换
            let mapKey = layerId + "_" + objectId
            if (this.flagMap.get(mapKey)) {
              this.flagMap.put(mapKey, false)
              this.api.tileLayer.stopHighlightActor(layerId, objectId)
              this.tileLayerSelMap.remove(mapKey)
            } else {
              this.flagMap.put(mapKey, true)
              this.api.tileLayer.highlightActor(layerId, objectId)
              // 记录选中的模型
              this.tileLayerSelMap.put(mapKey, {
                'layerId': layerId,
                'objectId': objectId,
              })
            }
            this.isMouseMoved = false
            this.resetPos()
          }
          break
        default:
          ""
      }
    },
    onLog (s, nnl) {
      // this.printLog('blue', `s：${s}`)
      // this.printLog('blue', `nnl：${nnl}`)
    },
    onLoaded () {
      // this.printLog('green', '视频流加载成功')
    },
    onClose () {
      // this.printLog('red', '连接断开')
    },
    onMouseDown (e) {
      this.pos.x = e.x
      this.pos.y = e.y
      // 1：左键，2：右键，4：滚轮
      if (e.buttons === 1) {
        this.lastLeftMouseButtonDownTime = new Date().getTime()
      } else if (e.buttons === 2) {
        // 打开右键菜单
        // this.dtsContextmenuVisible = true
        // this.$nextTick(() => {
        //   this.$refs.dtsContextmenu.init(this.pos)
        // })
      }
    },
    onMouseUp (e) {
      let dx = this.pos.x - e.x
	    let dy = this.pos.y - e.y
	    if (Math.sqrt(dx*dx+dy*dy) > 1) {
        this.isMouseMoved = true
      } else {
        this.isMouseMoved = false
      }
    },
    onKeyDown (e) {
      if (e && e.keyCode) {
        if (e.keyCode == 27) { // Esc键
          
        }
      }
    },
    // 注意：所有日志输出均使用该方法
    printLog(color, text) {
      console.log(`%c${text}`, `color: ${color}`);
    },
    // 创建各个marker标注
    addMarkers() {
      // 清空标注
      this.api.marker.clear()
    },
    // 打开项目选择（切换）弹窗
    openProjectChangeDialog() {
      this.dtsProjectSelectVisible = true
      this.$nextTick(() => {
        this.$refs.dtsProjectSelect.init()
      })
    },
    // 单击项目
    clickProjectHandle(p) {
      // this.printLog('orange', `切换了项目，id：${p.id}，name：${p.name}，pid：${p.dtsPid}`)
      this.currentPrjId = p.id
      this.options.pid = p.dtsPid
      this.api.destroy()
      this.dtsProjectSelectVisible = false
      this.dtsWbsSelectVisible = false
      this.dtsWbsBindToolbarVisible = false
      this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`, this.options)
      this.api = this.player.getAPI()
    },
    // 打开wbs选择弹窗
    openWbsSelectDialog() {
      this.dtsWbsSelectVisible = true
      this.$nextTick(() => {
        this.$refs.dtsWbsSelect.init(this.currentPrjId)
      })
    },
    // 打开wbs与模型关联的工具栏弹窗
    openWbsBindToolbarDialog() {
      this.dtsWbsBindToolbarVisible = true
      this.$nextTick(() => {
        this.$refs.dtsWbsBindToolbar.init()
      })
    },
    // 打开隐藏或显示模型工具面板
    openActorHideShowVisible() {
      this.dtsActorHideShowVisible = true
      this.$nextTick(() => {
        this.$refs.dtsActorHideShow.init()
      })
    },
    // 选中wbs
    async wbsClickHandle(wbs) {
      // this.printLog('blue', `选中了一个wbs，id：${wbs.id}，name：${wbs.name}`)
      this.currentWbs = wbs
      // 清空缓存的模型、取消高亮
      await this.clearTileLayerSelMap()
      // 先从后台查询已经挂接的模型信息
      const loading = this.$loading({
        lock: true,
        text: '正在查询已挂接的模型...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let {data: res} = await this.$http.get(`/opm/dtsWbs/list/${this.currentWbs.id}?prjId=${this.currentPrjId}`)
      loading.close()
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      if (res.data.length > 0) {
        // 随机定位一个模型
        if (this.focusActor) {
          await this.api.tileLayer.focusActor(res.data[0].layerId, res.data[0].objectId, 20, 2)
        }
        this.showActor()// 恢复隐藏的模型
        for (let i = 0; i < res.data.length; i++) {
          let layerId = res.data[i].layerId
          let objectId = res.data[i].objectId
          // 高亮模型
          await this.api.tileLayer.highlightActor(layerId, objectId)
          let mapKey = layerId + "_" + objectId
          // 缓存模型信息
          this.tileLayerSelMap.put(mapKey, {
            layerId: layerId,
            objectId: objectId,
          })
          this.flagMap.put(mapKey, true)
        }
      } else {
        this.$message({
          message: '未查询到关联的模型',
          type: 'warning',
          duration: 3000
        })
      }
    },
    // 双击wbs（当前页面为模型挂接功能，双击wbs暂无任何逻辑）
    wbsDblclickHandle(wbs) {
      // this.printLog('blue', `双击wbs，id：${wbs.id}，name：${wbs.name}`)
      this.currentWbs = wbs
    },
    // 清空模型缓存、取消高亮
    async clearTileLayerSelMap() {
      if (this.tileLayerSelMap.size() > 0) {
        let arr = this.tileLayerSelMap.values()
        for (const o of arr) {
          // 取消模型高亮
          await this.api.tileLayer.stopHighlightActor(o.layerId, o.objectId)
        }
        this.tileLayerSelMap.clear()
      }
    },
    // 保存wbs与点选的模型关联关系
    wbsBindToolbarClickHandle() {
      if (!this.currentWbs || !this.currentWbs.id) {
        return this.$message({
          message: '请选择要关联的分部分项工程！',
          type: 'error',
          duration: 4000,
        })
      }
      if (!this.tileLayerSelMap || this.tileLayerSelMap.size() == 0) {
        return this.$message({
          message: '请点选要关联的模型（支持模型多选）！',
          type: 'error',
          duration: 4000,
        })
      }
      if (this.hideActors.size() > 0) {
        // 如果存在隐藏的模型，需要提示用户是否也关联已经隐藏的模型
        this.$confirm(`发现隐藏的模型，是否将隐藏的模型包含在内？`, '确认', {
          confirmButtonText: '包含',
          cancelButtonText: '不包含',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          this.submitData(this.tileLayerSelMap.values())
        }).catch(() => {
          let vals = this.tileLayerSelMap.values()
          let dataArr = []
          for (let i = 0; i < vals.length; i++) {
            let key = vals[i].layerId + "_" + vals[i].objectId
            if (!this.hideActors.containsKey(key)) {
              dataArr.push(vals[i])
            }
          }
          this.submitData(dataArr)
        })
      } else {
        this.submitData(this.tileLayerSelMap.values())
      }
    },

    submitData(models) {
      const loading = this.$loading({
        lock: true,
        text: '正在保存...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 提交模型与wbs的绑定关系
      this.$http.post('/opm/dtsWbs',  {
          wbsId: this.currentWbs.id,
          prjId: this.currentPrjId,
          models: models
        }).then(({ data: res }) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        this.$message({
          message: '保存成功',
          type: 'success',
          duration: 4000
        })
      }).catch((e) => {
        this.$message.error('服务器（OPM）出现异常：' + e)
      }).finally(() => {
        loading.close()
      })
    },

    // 工具面板中可以控制是否自定定位
    focusActorChangeHandle (focusActor) {
      this.focusActor = focusActor
    },
    // 加载并选择默认项目，同时创建AirCityPlayer对象
    loadDefaultProjectAndMakeAirCityPlayer() {
      this.$http.get('/mps/project/openapi/briefsForMe').then(({ data: res }) => {
        if (res.code === 0) {
          let projects = res.data
          if (!projects || projects.length == 0) {
            return this.$message({
              message: '未查询到授权的项目！',
              type: 'error',
              duration: 30000
            })
          }
          projects.forEach((p) => {
            if (p.dtsDefault == 1) {
              this.currentPrjId = p.id
              this.options.pid = p.dtsPid
            }
          })
          // 如果所有项目都没设置默认，则选中第一个
          if (!this.currentPrjId || this.currentPrjId.length == 0) {
            this.currentPrjId = projects[0].id
            this.options.pid = projects[0].dtsPid
          }
          // this.printLog('blue', `默认项目ID：${this.currentPrjId}，pid：${this.options.pid}`)

          this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`, this.options)
          this.api = this.player.getAPI()
        }
      }).catch(() => {
        this.$message({
          message: '查询项目信息时出现错误，请重新登录系统。',
          type: 'error',
          duration: 30000
        })
      })
    },
    resetPos() {
      this.pos = {
        x: 0,
        y: 0
      }
    },
    // 隐藏模型
    hideActor() {
      if (!this.tileLayerSelMap || this.tileLayerSelMap.size() == 0) {
        return this.$message({
          message: '请鼠标点击要隐藏的模型',
          type: 'error',
          duration: 4000,
        })
      } else {
        let vals = this.tileLayerSelMap.values()
        for (let i = 0; i < vals.length; i++) {
          this.api.tileLayer.hideActor(vals[i].layerId, vals[i].objectId)
          let key = vals[i].layerId + "_" + vals[i].objectId
          this.hideActors.put(key, vals[i])
        }
      }
    },
    // 恢复隐藏的模型
    showActor() {
      if (this.hideActors && this.hideActors.size() > 0) {
        let vals = this.hideActors.values()
        for (let i = 0; i < vals.length; i++) {
          this.api.tileLayer.showActor(vals[i].layerId, vals[i].objectId)
        }
      }
      this.hideActors.clear()
    }
  }
}
</script>
<style lang="scss" scoped>
  body {
    background-color: honeydew;
    font-family: Verdana;
    font-size: 14px;
    overflow: hidden;
  }
  .container {
    width: 100%;
    height: 100%;
  }
  .player {
    position: relative;
    background-color: black;
    // margin-top: 5px;
    width: 100%;
    height: calc(100vh - 0px);
  }
  ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0,0,0,0.1);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  }
</style>