<template>
  <vxe-modal width="250" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>隐藏/显示模型</div>
    </span>
    <div class="dts-dialog-body">
      <el-button @click="hideActor" size="mini">隐藏模型</el-button>
      <el-button @click="showActor" size="mini">恢复模型</el-button>
      <!-- <el-button type="text" @click="hideActor" icon="el-icon-close" style="color: white;">隐藏模型</el-button>
      <el-button type="text" @click="showActor" icon="el-icon-refresh-left" style="color: white;">恢复模型</el-button> -->
    </div>
  </vxe-modal>
</template>

<script>
  // 隐藏或显示 模型 
  export default {
    data () {
      return {
        visible: false,
        focusActor: true,
        position: {
          top: 120, 
          left: `calc(100% - 260px)`
        }
      }
    },
    methods: {
      init () {
        this.visible = true
      },
      hideActor () {
        this.$emit('hideActor')
      },
      showActor () {
        this.$emit('showActor')
      },
    }
  }
</script>
<style lang="less" scoped>
.dts-dialog {
  
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  // .dts-dialog-body{
  //   text-align: center;
  //   button {
  //     padding-left: 5px;
  //     padding-right: 5px;
  //   }
  // }

  .dts-dialog-body {
    text-align: center;
    
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }
  }
}
</style>