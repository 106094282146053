<template>
  <vxe-modal class="outer" :position="position" :lockView="false" :mask="false" v-model="visible" :showHeader="false" width="660" height="60">
    <!-- <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>功能菜单</div>
      </span>
    </template> -->
    <template #default>
      <el-menu mode="horizontal" @select="selectHandle" class="inner">
        <el-menu-item index="项目切换" popper-class="my-dts-bg">
          <span slot="title" style="color: white">项目切换</span>
        </el-menu-item>
        <el-menu-item index="形象进度" popper-class="my-dts-bg">
          <span slot="title" style="color: white">形象进度</span>
        </el-menu-item>
        <el-menu-item index="进度模拟" popper-class="my-dts-bg">
          <span slot="title" style="color: white">进度模拟</span>
        </el-menu-item>
        <el-menu-item index="质量巡检" popper-class="my-dts-bg">
          <span slot="title" style="color: white">质量巡检</span>
        </el-menu-item>
        <el-menu-item index="安全巡检" popper-class="my-dts-bg">
          <span slot="title" style="color: white">安全巡检</span>
        </el-menu-item>
        <!-- <el-menu-item index="飞行漫游" popper-class="my-dts-bg">
          <span slot="title" style="color: white">飞行漫游</span>
        </el-menu-item> -->
        <el-submenu index="三维漫游" popper-class="my-dts-bg">
          <template slot="title">
            <span slot="title" style="color: white">三维漫游</span>
          </template>
          <el-menu-item index="人物漫游"><i class="el-icon-user" style="color: white"></i> 人物漫游</el-menu-item>
          <el-menu-item index="飞行漫游"><i class="el-icon-position" style="color: white"></i> 飞行漫游</el-menu-item>
          <el-menu-item index="车载漫游"><i class="el-icon-truck" style="color: white"></i> 车载漫游</el-menu-item>
        </el-submenu>
        <el-submenu index="智慧工地" popper-class="my-dts-bg">
          <template slot="title">
            <span slot="title" style="color: white">智慧工地</span>
          </template>
          <el-menu-item index="视频监控"><i class="el-icon-video-camera-solid"></i> 视频监控</el-menu-item>
          <el-menu-item index="环境监测"><i class="el-icon-wind-power"></i> 环境监测</el-menu-item>
          <el-menu-item index="环境监测"><i class="el-icon-s-claim"></i> 人员考勤</el-menu-item>
          <el-menu-item index="环境监测"><i class="el-icon-s-flag"></i> 人员定位</el-menu-item>
          <el-menu-item index="环境监测"><i class="el-icon-truck"></i> 车辆定位</el-menu-item>
          <el-menu-item index="环境监测"><i class="el-icon-odometer"></i> 塔机监控</el-menu-item>
        </el-submenu>
      </el-menu>
    </template>
  </vxe-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      position: {
        top: `calc(100vh - 60px)`,
        left: `calc(50% - 320px)`
      }
    }
  },
  mounted(){
    
  },
  methods: {
    init () {
      this.visible = true
    },
    selectHandle (index, indexPath) {
      this.$emit('select', index)
    }
  }
}
</script>
<style lang="less" scoped>
  ::v-deep .outer {
    align-items: center;
    justify-content: center;
    width: 660px;
    // height: 10px;
    filter: drop-shadow(0 5px 12px rgba(149, 224, 242, 0.75));
  }
  ::v-deep .inner {
    position: relative;
    align-items: center;
    justify-content: center;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    box-shadow: inset 0 0 14px 3px rgba(146, 244, 243, 0.85);
    clip-path: polygon(
        0 17px,
        17px 0,
        100% 0,
        100% calc(100% - 17px),
        calc(100% - 17px) 100%,
        0 100%
    );
  }
  ::v-deep .inner:before,
  ::v-deep .inner:after {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      box-shadow: 0 0 14px 3px rgba(146, 244, 243, 0.55);
  }
  ::v-deep .inner:before {
      left: -28px;
      top: -28px;
  }
  ::v-deep .inner:after {
      bottom: -28px;
      right: -28px;
  }
    

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    overflow: hidden !important;
    border: 0;
    // border: rgb(255, 255, 255) 1px solid;
    // border-radius: 40px;
    // border-bottom-left-radius: 6px 4px;
    // border-bottom-right-radius: 6px 4px;
  }


  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    overflow: hidden !important;
    padding: 0;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  ::v-deep .el-menu.el-menu--horizontal {
    padding-left: 25px;
    border-bottom: 0;
    padding-top: 5px;
    font-weight: bold;
    color: white !important;
  }
  
  ::v-deep .el-menu--horizontal>.el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
    color: white !important;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
    color: white !important;
  }

  ::v-deep .el-menu--horizontal>.el-menu-item:hover {
    background-color: #003333!important;
    color: white;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: #003333!important;
  }

  ::v-deep .el-menu {
    color: white !important;
    background-color: transparent;
  }
  
  ::v-deep .el-menu--popup {
    color: white !important;
    background-color: transparent;
  }

  ::v-deep .el-menu-item {
    background-color: transparent !important;
    margin-left: -14px !important;
    color: white !important;
  }
  ::v-deep .el-submenu {
    background-color: transparent !important;
    margin-left: -14px !important;
    color: white !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item {
    background-color: transparent !important;
    color: white !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item:hover {
    background-color: #003333!important;
  }
</style>