<template>
  <vxe-modal width="360" :position="{top: 20, left: 20}" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>项目选择</div>
      </span>
    </template>
    <template #default>
      <div class="dts-dialog-body">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          max-height="400"
          v-loading="tableDataLoading"
          @row-click='clickHandle'
          @row-dblclick="dblclickHandle"
          :header-cell-style="{
            background: 'rgba(40, 118, 193, .2)',
            color: 'white',
            border: 'rgb(255, 255, 255)',
            textAlign: 'center',
            fontSize: '15px',
            padding: '1',
          }"
        >
          <el-table-column prop="name" label="项目名称">
            <template v-slot="{row}">
              <span v-if="defaultPrjId === row.id">{{ row.name }} <i class="vxe-icon-check"></i></span>
              <span v-if="defaultPrjId !== row.id">{{ row.name }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
import Cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      tableData: [],
      visible: false,
      tableDataLoading: false,
      prjId: '',
    }
  },
  props: {
    defaultPrjId: {
      type: [String, Number],
      required: true
    },
  },
  mounted(){
    
  },
  methods: {
    init () {
      this.visible = true
      this.tableDataLoading = true
      this.$nextTick(() => {
        // 查询权限内的项目
        let url = '/mps/project/openapi/briefsForUser'
        this.$http.get(url).then(({ data: res }) => {
          this.tableDataLoading = false
          if (res.code === 0) {
            this.tableData = res.data
          }
        }).catch(() => {
          this.tableDataLoading = false
        })
      })
    },
    // 双击切换项目
    dblclickHandle(p) {
      if (p.id != this.prjId) {
        this.$emit('change', p)
      }
    },
    // 单击项目
    clickHandle(p) {
      this.$emit('click', p)
    }
  }
}
</script>
<style lang="less" scoped>
 .dts-dialog {
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: white 1px solid;
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
    // backdrop-filter: blur(3px); //模糊
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    ::v-deep .el-table {
      color: white;
      background-color: transparent;
      border: white 1px solid;
      border-bottom: none;
    }

    ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }

    // ::v-deep .el-table tr:hover {
    //   cursor: pointer;
    //   background-color: #003333!important;
    // }

    ::v-deep .el-table tr {
      background-color: transparent !important;
    }
    ::v-deep .el-table__body tr:hover > td {
      cursor: pointer;
      background-color: #003333!important;
    }

    // ::v-deep .el-table--enable-row-transition .el-table__body td, .el-table .cell {
    //   font-size: 12px;
    //   color: #fff;
    //   text-align: center;
    //   background-color: transparent;
    //   border: rgb(40, 118, 193) 1px solid;
    // }

    // ::v-deep .el-table::before {
    //   left: 0;
    //   bottom: 0;
    //   width: 100%;
    //   height: 0px;
    // }
  }
}
</style>