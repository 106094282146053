<template>
  <vxe-modal ref="vxeModal" width="400" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" :show-zoom="true" :resize="true" @zoom="zoomHandle" v-on="$listeners">
    <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>视频监控</div>
      </span>
    </template>
    <template #default>
      <div class="dts-dialog-body">
        <iframe :src="liveUrl" id="iframe1" width="100%" height="400" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="yes"></iframe>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
import Cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      position: {
        top: 10, 
        left: 10
      },
      liveUrl: '',
    }
  },
  methods: {
    init (liveUrl, left) {
      this.position.left = left
      this.visible = true
      this.$nextTick(() => {
        this.liveUrl = liveUrl
      })
    },
    zoomHandle() {
      document.getElementById("iframe1").height = this.$refs['vxeModal'].$refs.modalBox.offsetHeight - 70
      if (this.liveUrl.indexOf('?') > 0) {
        this.liveUrl = `${this.liveUrl}&_time=${new Date().getTime()}`
      } else {
        this.liveUrl = `${this.liveUrl}?_time=${new Date().getTime()}`
      }
    }
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
 .dts-dialog {

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: white 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }
  ::v-deep .vxe-modal--zoom-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333 !important;
    }
  }
}
</style>