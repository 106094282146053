<template>
  <vxe-modal width="360" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>形象进度</div>
    </span>
    <div class="dts-dialog-body">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        max-height="300"
        v-loading="tableDataLoading"
        :header-cell-style="{
          background: 'rgba(40, 118, 193, .2)',
          color: 'white',
          border: 'rgb(255, 255, 255)',
          textAlign: 'center',
          fontSize: '15px',
          padding: '1',
        }"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="unitName" label="单位"></el-table-column>
        <el-table-column prop="totalNum" label="工程量"></el-table-column>
        <el-table-column prop="progress" label="施工进度" width="100" header-align="center" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.progress * 100}} %</span>
          </template>
        </el-table-column>
      </el-table>
      
      <el-row :gutter="2" style="margin-top: 5px">
        <el-col :span="4"><div style="color: white; white-space: nowrap">图例：</div></el-col>
        <el-col :span="10"><div style="background-color:#ebae0a; text-align: center; color: white;">已开工</div></el-col>
        <el-col :span="10"><div style="background-color:#37ddeb; text-align: center; color: white; padding-left: 4px;">已完工</div></el-col>
      </el-row>
      <!-- <el-row :gutter="2" style="margin-top: 8px">
        <el-col :span="24">
          <el-button @click="query">查询</el-button>
        </el-col>
      </el-row> -->
    </div>
  </vxe-modal>
</template>
<!--形象进度查询-->
<script>
  export default {
    data () {
      return {
        tableData: [],
        visible: false,
        tableDataLoading: false,
        position: {
          top: 10,
          left: `calc(100% - 363px)`
        }
      }
    },
    methods: {
      init (prjId) {
        this.visible = true
        this.tableDataLoading = true
        this.$nextTick(() => {
          // 触发模型颜色渲染
          this.$emit('query')
          // 查询形象工程量统计
          this.$http.get('/opm/scheduleUnit/scheduleProgress', {
            params: {
              prjId: prjId,
            }
          }).then(({ data: res }) => {
            this.tableDataLoading = false
            if (res.code === 0) {
              this.tableData = res.data
            }
          }).catch(() => {
            this.tableDataLoading = false
          })
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  // el-dialog的位置、尺寸、背景透明、字体颜色
  .dts-dialog {

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    padding: 5px 5px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    text-align: right;

    ::v-deep .el-table {
      color: white;
      background-color: transparent;
      border: white 1px solid;
      border-bottom: none;
    }

    ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }

    // ::v-deep .el-table tr:hover {
    //   cursor: pointer;
    //   background-color: #003333!important;
    // }

    ::v-deep .el-table tr {
      background-color: transparent !important;
    }
    ::v-deep .el-table__body tr:hover > td {
      cursor: pointer;
      background-color: #003333!important;
    }

    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }

    ::v-deep .el-col-4 {
      width: 40px !important;
    }
    ::v-deep .el-col-10 {
      width: 70px !important;
    }
  }
}
</style>