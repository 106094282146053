<template>
  <vxe-modal :position="position" :lockView="false" :mask="false" v-model="visible" :showHeader="false" width="600" height="36">
    <template #default>
      <div style="text-align: center; color: #888888; font-size: large; font-weight: bold;">绘 制 车 载 路 线</div>
    </template>
  </vxe-modal>
</template>

<script>
// 车载路线绘制组件窗口的头部标题
export default {
  data () {
    return {
      visible: false,
      position: {
        top: `2px`,
        left: `calc(50% - 390px)`
      }
    }
  },
  mounted(){
    
  },
  methods: {
    init () {
      this.visible = true
    },
  }
}
</script>
<style lang="less" scoped>
 ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    // background: rgba(0, 0, 0, .9) !important;
    background: transparent;
    background-image: url(../../../assets/img/banner1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    overflow: hidden !important;
    
    border: rgb(255, 255, 255) 1px solid;
    border-radius: 40px;

    border-top-left-radius: 6px 4px;
    border-top-right-radius: 6px 4px;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
    overflow: hidden !important;
    padding: 2px
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  ::v-deep .el-menu.el-menu--horizontal {
    padding-left: 10px;
    border-bottom: 0;
  }
  
  ::v-deep .el-menu--horizontal>.el-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    color: white;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
    height: 30px !important;
    line-height: 30px !important;
  }

  ::v-deep .el-menu--horizontal>.el-menu-item:hover {
    background-color: #003333!important;
    color: white;
  }

  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background-color: #003333!important;
  }

  ::v-deep .el-menu {
    color: white;
    background-color: transparent;
  }

  ::v-deep .el-menu--popup {
    color: white;
    background-color: transparent;
  }

  ::v-deep .el-menu-item {
    background-color: transparent !important;
    margin-left: -14px !important;
  }
  ::v-deep .el-submenu {
    background-color: transparent !important;
    margin-left: -14px !important;
  }
  .el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
    background-color: transparent !important;
    color: white !important;
  }
</style>