<template>
  <vxe-modal width="250" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>模型挂接模式</div>
    </span>
    <div class="dts-dialog-body">
      <el-switch v-model="focusActor" active-text="自动定位" @change="changeFocusActor" style="padding-right: 25px;"></el-switch>
      <el-button @click="clickHandle" size="mini">保存结果</el-button>
    </div>
  </vxe-modal>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        focusActor: true,
        position: {
          top: 10, 
          left: `calc(100% - 260px)`
        }
      }
    },
    methods: {
      init () {
        this.visible = true
      },
      // 保存
      clickHandle() {
        this.$emit('click')
      },
      // 控制是否自动定位
      changeFocusActor() {
        this.$emit('focusActorChangeHandle', this.focusActor)
      }
    }
  }
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
.dts-dialog {
  
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    text-align: center;
    
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }
  }
}
</style>