<template>
  <vxe-modal width="360" :position="{top: 20, left: 20}" class="dts-dialog" :lockView="false" :mask="false" v-model="visible">
    <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>地形透明度</div>
      </span>
    </template>
    <template #default>
      <div class="dts-dialog-body">
        <div class="block">
          <el-slider
              v-model="terrain"
              style="width: 95%"
              :min="0"
              :max="1"
              @input="terrainChange"
              :step="0.1">
          </el-slider>
        </div>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      terrain: 1,
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
      })
    },
    terrainChange(e){
      this.$emit('terrainChange', e)
    }
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
 .dts-dialog {

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: white 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }
}
</style>