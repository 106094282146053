import { render, staticRenderFns } from "./dts-cctv.vue?vue&type=template&id=e24b4fe2&scoped=true"
import script from "./dts-cctv.vue?vue&type=script&lang=js"
export * from "./dts-cctv.vue?vue&type=script&lang=js"
import style0 from "./dts-cctv.vue?vue&type=style&index=0&id=e24b4fe2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24b4fe2",
  null
  
)

export default component.exports