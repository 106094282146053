<template>
  <vxe-modal :zIndex="1500" width="360" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>车载漫游</div>
    </span>
    <div class="dts-dialog-body">
      <el-row v-show="selectVisible">
        <el-col :span="24">
          <el-select v-model="vehicleWaypointId" placeholder="请选择车载路线" :disabled="selectDisabled" :popper-append-to-body="false" popper-class="my-select">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      
      <el-row :gutter="4" style="padding-top: 10px;">
        <el-col :span="8">
          <el-switch v-model="showVehicleAsset" active-text="显示载具" @change="changeVehicleAssetVisible" :disabled="showVehicleSwitchDisabled" class="switch"></el-switch>
        </el-col>
        <el-col :span="8">
          <el-switch v-model="showVehicleRoamingPolyline" active-text="箭头导引" @change="changeVehicleRoamingPolylineVisible" :disabled="roamingPolylineSwitchDisabled" class="switch"></el-switch>
        </el-col>
        <el-col :span="8">
          <el-switch v-model="showVisualSchedule" active-text="形象进度" @change="changeVisualScheduleVisible" :disabled="VisualScheduleDisabled" class="switch"></el-switch>
        </el-col>
      </el-row>

      <el-row style="padding-top: 10px;">
        <el-col :span="24">
          <el-radio-group v-model="speed" :disabled="speedDisabled" @input="speedInputHandle">
            <el-radio :label="2000">低速</el-radio>
            <el-radio :label="1000">中速</el-radio>
            <el-radio :label="500">快速</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      
      <el-row :gutter="4" style="padding-top: 10px;">
        <el-col :span="24">
          <el-button-group>
            <el-button @click="startRoaming" size="mini">开始</el-button>
            <el-button @click="pauseOrResume" size="mini">暂停/恢复</el-button>
            <el-button @click="stopRoaming" size="mini">停止</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
  </vxe-modal>
</template>

<script>
  // 车载漫游
  export default {
    data () {
      return {
        visible: false,
        vehicleWaypointId: '',
        selectVisible: true,
        speed: 1000,
        options: [],
        showVehicleAsset: true,// 是否显示载具
        showVehicleRoamingPolyline: true, // 是否显示箭头导线
        selectDisabled: false,
        showVehicleSwitchDisabled: false,// 控制载具显隐的按钮是否允许编辑
        roamingPolylineSwitchDisabled: false,
        speedDisabled: false,// 改变车速的按钮是否允许编辑

        // 开启形象进度
        showVisualSchedule: false,
        VisualScheduleDisabled: false,

        position: {
          top: 10, 
          left: `calc(100% - 363px)`
        }
      }
    },
    methods: {
      init (prjId) {
        this.visible = true
        this.selectDisabled = false
        this.showVehicleSwitchDisabled = false
        this.roamingPolylineSwitchDisabled = false
        this.VisualScheduleDisabled = false
        this.showVisualSchedule = false
        this.speedDisabled = false
        this.options = []
        // 查询该项目的所有车载路线
        this.$http.get(`/opm/vehicleWaypoint/options?prjId=${prjId}`).then(({ data: res }) => {
          if (res.code === 0) {
            this.options = res.data
            if (this.options && this.options.length > 0) {
              // 如果只有一个车载路线，则默认选中，并且隐藏下拉框
              if (this.options.length == 1) {
                this.selectVisible = false
                this.vehicleWaypointId = this.options[0].id
              }
            } else {
              this.selectVisible = false
              return this.$message({
                message: '未查询到该项目的车载路线，请在车载路线绘制菜单中绘制路线。',
                type: 'error',
                duration: 5000
              })
            }
          }
        }).catch(() => {
          // do nothing
        })
      },
      startRoaming () {
        this.selectDisabled = true
        this.showVehicleSwitchDisabled = true
        this.roamingPolylineSwitchDisabled = true
        this.VisualScheduleDisabled = true
        this.speedDisabled = true

        // 加载车载路线点
        if (this.vehicleWaypointId) {
          this.$http.get(`/opm/vehicleWaypoint/coordinates?mainId=${this.vehicleWaypointId}`).then(({ data: res }) => {
            if (res.code === 0) {
              let dtos = res.data
              if (dtos && dtos.length > 0) {
                let coordinates = []
                for (let i = 0; i < dtos.length; i++) {
                  coordinates.push([dtos[i].x, dtos[i].y, dtos[i].z])
                }
                this.$emit('startRoaming', coordinates)
              } else {
                this.$emit('startRoaming', [])
                return this.$message({
                  message: '未查询到对应的车载路线点',
                  type: 'error',
                  duration: 5000
                })
              }
            } else {
              this.$emit('startRoaming', [])
            }
          }).catch(() => {
            // do nothing
          })
        } else {
          this.$emit('startRoaming', [])
        }
      },
      stopRoaming () {
        this.selectDisabled = false
        this.showVehicleSwitchDisabled = false
        this.roamingPolylineSwitchDisabled = false
        this.VisualScheduleDisabled = false
        this.speedDisabled = false
        this.$emit('stopRoaming')
      },
      // 控制是否显示车辆模型
      changeVehicleAssetVisible() {
        this.$emit('changeVehicleAssetVisible', this.showVehicleAsset)
      },
      // 控制是否显示箭头导引线
      changeVehicleRoamingPolylineVisible() {
        this.$emit('changeVehicleRoamingPolylineVisible', this.showVehicleRoamingPolyline)
      },
      // 开启形象进度
      changeVisualScheduleVisible () {
        this.$emit('changeVisualScheduleVisible', this.showVisualSchedule)
      },
      // 修改车载速度
      speedInputHandle(e) {
        this.$emit('changeSpeed', e)
      },
      // 暂停/恢复
      pauseOrResume() {
        this.$emit('pauseOrResume')
      },
    }
  }
</script>
<style lang="less" scoped>
.dts-dialog {
  ::v-deep .el-input--small .el-input__inner{
    background: transparent;
    width: 300px;
    height: 25px;
    line-height: 25px;
    color: white;
  }
  
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  ::v-deep .my-select {
    text-align: left;
    //color: white !important;
    background: rgba(0, 0, 0, .8) !important;
  }

  .el-select-dropdown__item {
    color: white !important;
  }

  .el-select-dropdown__item.hover {
    color: #006699 !important;
  }

  // .dts-dialog-body{
  //   text-align: center;
  //   button {
  //     padding-left: 5px;
  //     padding-right: 5px;
  //   }
  // }

  .dts-dialog-body {
    text-align: center;
    
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }

    ::v-deep .switch .el-switch__label {
      color: white;
    }

    .el-radio {
      color: white;
    }
  }
}
</style>