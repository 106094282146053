<template>
  <vxe-modal width="320" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>绘制车载路线</div>
    </span>
    <div class="dts-dialog-body">
      <el-row :gutter="4">
        <el-col :span="24">
          <el-button-group>
            <el-button @click="start" size="mini">开始</el-button>
            <el-button @click="stop" size="mini">结束</el-button>
            <el-button @click="play" size="mini">浏览</el-button>
            <el-button @click="clear" size="mini">重置</el-button>
            <el-button @click="save" size="mini">保存</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
  </vxe-modal>
</template>

<script>
  // 车载漫游路线绘制组件的工具栏面板 
  export default {
    data () {
      return {
        visible: false,
        position: {
          top: 10, 
          left: `calc(100% - 330px)`
        }
      }
    },
    methods: {
      init () {
        this.visible = true
      },
      // 开始绘制
      start () {
        this.$emit('start')
      },
      // 结束绘制
      stop () {
        this.$emit('stop')
      },
      // 重置数据
      clear () {
        this.$emit('clear')
      },
      // 播放漫游
      play () {
        this.$emit('play')
      },
      // 保存数据
      save() {
        this.$emit('save')
      },
    }
  }
</script>
<style lang="less" scoped>
.dts-dialog {
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }
  }

  .dts-dialog-body {
    text-align: center;
    
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333!important;
    }
  }
}
</style>