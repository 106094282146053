<template>
  <vxe-modal width="300" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible">
    <template #title>
      <span class="dts-dialog-title">
        <i class="el-icon-location-outline"></i>
        <div>天气选择</div>
      </span>
    </template>
    <template #default>
      <div class="dts-dialog-body">
        <div class="block">
          <span style="color: white;">云层特效</span>
          <el-slider
              v-model="cloud"
              style="width: 95%"
              :min="0"
              :max="1"
              @input="cloudOnInput"
              :step="0.1">
          </el-slider>
          <span style="color: white;">下雨特效</span>
          <el-slider
              v-model="rain"
              style="width: 95%"
              :min="0"
              :max="1"
              @input="rainOnInput"
              :step="0.1">
          </el-slider>
          <span style="color: white;">下雪特效</span>
          <el-slider
              v-model="snow"
              style="width: 95%"
              :min="0"
              :max="1"
              @input="snowOnInput"
              :step="0.1">
          </el-slider>
          <el-button @click="resetWeather" style="height: 30px; width: 80px; margin-left: 100px;" size="mini">重置特效</el-button>
        </div>
      </div>
    </template>
  </vxe-modal>
</template>

<script>
import Cookies from 'js-cookie'
import debounce from 'lodash/debounce'
import { getUUID } from '@/utils'
export default {
  data () {
    return {
      visible: false,
      position: {
        top: 50, 
        left: `calc(100% - 400px)`
      },
      cloud: 0,
      rain: 0,
      snow: 0,
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
      })
    },
    cloudOnInput(e) {
      this.$emit('cloudChange', e)
    },
    rainOnInput(e) {
      this.$emit('rainChange', e)
    },
    snowOnInput(e) {
      this.$emit('snowChange', e)
    },
    resetWeather() {
      this.cloud = 0
      this.rain = 0
      this.snow = 0
      this.$emit('reset')
    }
  }
}
</script>
<style lang="less" scoped>
 // el-dialog的位置、尺寸、背景透明、字体颜色
 .dts-dialog {

  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: white 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    ::v-deep button:hover {
      background-color: #006699 !important;
    }

    ::v-deep button {
      color: white;
      background-color: #003333 !important;
    }
  }
}
</style>